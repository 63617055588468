/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";


@import '~animate.css/animate.min.css';
//@import '../node_modules/animate.css/animate.min.css';

  

@import '~@angular/material/theming';

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat-typography-config(
      $font-family: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      $headline: mat-typography-level(32px, 48px, 700),
      $body-1: mat-typography-level(16px, 24px, 500)
);
// Override typography for all Angular Material, including mat-base-typography and all components.
@include angular-material-typography($custom-typography);


